import { LANGUAGE } from "../../actions/constants"

const WEDDING_FUND_LINK =
  "https://tribee.fr/cagnotte/0419QVC6SB861RQT78J3Z2G07S/participation"
export default {
  [LANGUAGE.EN]: {
    Heading: () => <>Wedding Fund</>,
    SubHeading: () => (
      <>
        We have set up a wedding fund for our guests to contribute to{" "}
        <a href={WEDDING_FUND_LINK} rel="noopener" target="_blank">
          link
        </a>
      </>
    ),
  },
  [LANGUAGE.FR]: {
    Heading: () => <>Cagnotte mariage</>,
    SubHeading: () => (
      <>
        Nous avons créer une cagnotte de mariage si vous souhaitez nous aider à
        financer notre lune de miel{" "}
        <a href={WEDDING_FUND_LINK} rel="noopener" target="_blank">
          lien
        </a>
      </>
    ),
  },
  [LANGUAGE.ES]: {
    Heading: () => <>Fondo de boda</>,
    SubHeading: () => (
      <>
        We have set up a wedding fund for our guests to contribute to{" "}
        <a href={WEDDING_FUND_LINK} rel="noopener" target="_blank">
          Haz clic en el enlace para visitar el sitio web
        </a>
      </>
    ),
  },
}
