import React from "react"
import { useCookies } from "react-cookie"

import { selectLanguage } from "../../utilities/cookies"
import { weddingFund } from "../../content/WeddingFund"
import { Header } from "../../components/Header"

function WeddingFund() {
  const [cookies] = useCookies(["language"])

  const { Heading, SubHeading } = weddingFund[selectLanguage(cookies)]

  return (
    <>
      <Header Heading={Heading} SubHeading={SubHeading} />
    </>
  )
}

export default WeddingFund
