export const APP = {
  SET: {
    // user
    INITIALIZE_USER: "SET_APP_INITIALIZE_USER",
    USER_SIGN_IN: "SET_APP_USER_SIGN_IN",
    USER_SIGN_OUT: "SET_APP_USER_SIGN_OUT",
    USER_ERROR: "SET_APP_USER_ERROR",

    // rsvp
    RSVP: "SET_APP_RSVP",
  },
}

export const LANGUAGE = {
  EN: "English",
  ES: "Spanish",
  FR: "French",
}
